// このファイルはWebpackによって自動的にコンパイルされます。このディレクトリ内の他のファイルと一緒に。
// 実際のアプリケーションロジックをapp/javascript内の関連する構造に配置し、これらのパックファイルを使用してそのコードを参照することを推奨します。
// それにより、コードがコンパイルされます。
// import '/var/www/photopic/app/assets/stylesheets/application.css';
import "trix"
import "@rails/actiontext"

import '../stylesheets/form.css'
import Rails from "@rails/ujs"
import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage"
import '@rails/actioncable';
import "channels"
import $ from 'jquery';
import 'jquery-ui-dist/jquery-ui';

import '@fortawesome/fontawesome-free/css/all.css';
import { toggleChildrenTag } from './tree_tag';
//= require ckeditor/init

// Rails、Turbolinks、ActiveStorageを起動
Rails.start()
Turbolinks.start()
ActiveStorage.start()


window.toggleChildrenTag = toggleChildrenTag;

// お気に入りの取得とトグル
window.getFavorite = async function(id) {
    let fid = Number(id);
    let csrfToken = document.querySelector('meta[name="csrf-token"]').getAttribute('content');
    if (isNaN(fid)) {
        console.error('IDが数値ではありません');
        return;
    }
    try {
        const response = await fetch(`/api/favorites/toggle?id=${fid}`, {
            method: 'POST',
            headers: {
                'X-CSRF-Token': csrfToken ,
                'Content-Type': 'application/json'
            }
        });
        const data = await response.json();
        if (data.status === 'SUCCESS') {
            if(data.action === "create") {
                document.getElementById(`fav_tag__${id}`).querySelector('img').src = "/images/heart-on_icon.png";
            } else {
                document.getElementById(`fav_tag__${id}`).querySelector('img').src = "/images/heart_icon.png";
            }
        } else {
            console.error('サーバーエラーが発生しました');
        }
    } catch (error) {
        console.error('エラーが発生しました:', error);
    }
}

// 検索コンテナのトグル
window.searchContainerToggle = function () {
    const searchFormContainer = document.getElementById('searchForm')
    const arrow = document.getElementById('searchContainerArrow');
    if (searchFormContainer.style.display === 'none') {
        searchFormContainer.style.display = 'block';
        arrow.className = 'fas fa-chevron-up';
    } else {
        searchFormContainer.style.display = 'none';
        arrow.className = 'fas fa-chevron-down';
    }
}
window.removeItemProduct = async function(itemId) {
    let id = Number(itemId);
    let csrfToken = document.querySelector('meta[name="csrf-token"]').getAttribute('content');
    if (isNaN(id)) {
        console.error('IDが数値ではありません');
        return;
    }
    try {

        const response = await fetch('/api/product_collection_items/' + itemId, {
            method: 'DELETE',
            headers: {
                'X-CSRF-Token': csrfToken ,
                'Content-Type': 'application/json'
            }
        });
        const data = await response.json();
        if (data.status === 'SUCCESS') {
            document.getElementById(`list__${id}`).remove();
            document.getElementById(`check_list__${data.product_id}`).classList.remove('checked');
            updatePositions();
        } else {
            console.error('サーバーエラーが発生しました');
        }
        
    } catch (error) {
        console.error('エラーが発生しました:', error);
    }
}

// ポジションの更新
function updatePositions() {
    console.log("start updatePositions")
    var items = document.querySelectorAll("#selectSortMarks li");
    for (var i = 0; i < items.length; i++) {
        var positionInput = items[i].querySelector('input[name="product_collection[product_collection_items_attributes][][position]"]');
        if (positionInput) {
            positionInput.value = i + 1;
        }
    }
}

document.addEventListener('turbolinks:load', function() {
    const checkradios = document.querySelectorAll('#manufactureRadios input[type="radio"]');

    checkradios.forEach(function(checkbox) {
        const li = checkbox.closest('li');
        
        // ページロード時にチェックが入っている場合、クラスを追加
        if (checkbox.checked) {
            li.classList.add('checked');
        }
        
        // チェックボックスの状態が変わったときにクラスを追加/削除
        checkbox.addEventListener('change', function() {
            // すべてのラジオボタンに対応するリストアイテムからcheckedクラスを削除
            checkradios.forEach(radio => {
            radio.closest('li').classList.remove('checked');
            });
        
            // 現在のラジオボタンをチェック
            if (this.checked) {
            li.classList.add('checked');
            }
        });
    });

    const checkboxes = document.querySelectorAll('#checkMarks input[type="checkbox"]');

    checkboxes.forEach(function(checkbox) {
        const li = checkbox.closest('li');

        // ページロード時にチェックが入っている場合、クラスを追加
        if (checkbox.checked) {
            li.classList.add('checked');
            // 親要素のulからhiddenクラスを外す（多重の入れ子に対応）
            let parent = li.parentElement;
            while (parent) {
                if (parent.tagName === 'UL' && parent.classList.contains('hidden')) {
                    parent.classList.remove('hidden');
                }
                parent = parent.parentElement;
            }
        }

        // チェックボックスの状態が変わったときにクラスを追加/削除
        checkbox.addEventListener('change', function() {
            if (this.checked) {
                li.classList.add('checked');
            } else {
                li.classList.remove('checked');
            }
        });
    });



    // Sortableを初期化
    $("#selectSortMarks").sortable({
        update: function(event, ui) {
            updatePositions();
        }
    });




    document.querySelectorAll('.remove-btn').forEach(function(button) {
        button.addEventListener('click', function(e) {
            e.stopPropagation(); // チェックボックスのクリックイベントを防ぐ
            var productDiv = button.closest('li');
            var destroyField = productDiv.querySelector('input[name="product_collection[product_collection_items_attributes][][_destroy]"]');
            if (destroyField) {
                destroyField.value = '1'; // _destroyフィールドの値を1に設定
                productDiv.style.display = 'none'; // リスト項目を非表示にする
            }
            var productIdInput = productDiv.querySelector('input[name="product_collection[product_collection_items_attributes][][product_id]"]');
            if (productIdInput) {
                var checkboxValue = productIdInput.value;
                var checkbox = document.querySelector('#checkMarks input[value="' + checkboxValue + '"]');
                if (checkbox) {
                    checkbox.checked = false; // 左側のチェックボックスのチェックを外す
                    checkbox.closest('li').classList.remove('checked'); // 'checked'クラスを外す
                }
            }
            updatePositions();
        });
    });
    // チェックボックスがクリックされたときのイベント
    document.querySelectorAll("#checkMarks input[type=checkbox]").forEach(function(checkbox) {
        checkbox.addEventListener('click', function() {
            var productImage = checkbox.closest('li').querySelector('.image').innerHTML;
            var productName = checkbox.closest('li').querySelector('p').textContent;
            var checkboxValue = checkbox.value;
            var productDiv = document.createElement('li');
            productDiv.className = `check_list__${checkboxValue}`;
            productDiv.innerHTML = `
                    <label>
                        <input type="hidden" name="product_collection[product_collection_items_attributes][][product_id]" value="${checkboxValue}">
                        <input type="hidden" name="product_collection[product_collection_items_attributes][][position]">

                        <div class="image">${productImage}</div>
                        <p>${productName}</p>
                        <button class="remove-btn">✖</button>
                    </label>`;

            if (checkbox.checked) {
                // チェックが入ったら右側のリストに追加
                if (!document.querySelector("#selectSortMarks .check_list__" + checkbox.value)) {
                    document.querySelector("#selectSortMarks").appendChild(productDiv);
                }
            } else {
                // チェックが外れたら右側のリストから削除
                var productToRemove = document.querySelector("#selectSortMarks .check_list__" + checkbox.value);
                if (productToRemove) {
                    productToRemove.remove();
                }
            }

            // リスト項目の削除ボタンがクリックされたときのイベント
            productDiv.querySelector('.remove-btn').addEventListener('click', function(e) {
                e.stopPropagation(); // チェックボックスのクリックイベントを防ぐ
                productDiv.remove(); // リスト項目を削除
                checkbox.checked = false; // 左側のチェックボックスのチェックを外す
                checkbox.closest('li').classList.remove('checked'); // 'checked'クラスを外す
            });

            // ポジションを更新
            updatePositions();
        });
    });


});

// trixとactiontextを要求
require("trix")
require("@rails/actiontext")